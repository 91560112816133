import React from 'react'
import "./styling_folder/generalSiteStyling.css"

export default function FooterCopyRights() {
  return (
    <footer>
        <p>
        Copyrights Reserved To CCX LTD
        </p>
    </footer>
  )
}
